<script>
import Layout from "../../layouts/table";
import {mapGetters} from "vuex";
import Swal from 'sweetalert2'

/**
 * Profile component
 */
export default {
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            sortBy: 'date',
            sortDesc: true,
            modal: false
        };
    },
    computed: {
        ...mapGetters({
            items: 'message/list',
            item: 'message/item'
        }),
        rows: function () {
            return this.items && this.items.length
        },
        fields: function () {
            return [
                {
                    key: "type",
                    label: this.$t('messages.type'),
                    thClass: 'gridjs-th border-left-0',
                    sortable: true
                },
                {
                    key: "sender",
                    label: this.$t('messages.recipient'),
                    thClass: 'gridjs-th',
                    sortable: true
                },
                {
                    key: "create_date",
                    label: this.$t('messages.type'),
                    thClass: 'gridjs-th',
                    sortable: true
                },
                {
                    key: "subject",
                    label: this.$t('messages.subject'),
                    thClass: 'gridjs-th',
                    sortable: true
                },
                {
                    key: "actions",
                    label: '',
                    thClass: 'gridjs-th',
                    tdClass: 'text-end',
                    sortable: true
                },
            ];
        },
    },
    methods: {
        open: function (id) {
            return this.$store.dispatch('message/fetchOne', id).then(() => {
                this.modal = true;
            });
        },

        destroy: function (id) {
            Swal.fire({
                title: this.$t('table.confirm'),
                text: this.$t('table.confirm_delete'),
                icon: 'warning',
                showCancelButton: true,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-danger btn-md me-1',
                    cancelButton: 'btn btn-primary btn-md ms-1',
                    icon: 'text-danger border-danger'
                },
                confirmButtonText: this.$t('table.yes'),
                cancelButtonText: this.$t('table.no')
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('message/destroy', id).then(() => {
                        Swal.fire(
                            this.$t('request.delete_success'),
                            this.$t('table.selected_item_is_deleted'),
                            'success'
                        )
                    });
                }
            })
        },

        read: function () {
            if (this.$route.params.id) {
                this.open(this.$route.params.id)
                    .then(() => {
                        this.$store.dispatch('notification/changeRead', this.$route.params.id);
                    });
            }
        }
    },
    components: {
        Layout,
    },

    watch: {
        '$route.params.id': {
            deep: true,
            handler: function (value) {
                if (value) {
                    this.read();
                }
            },
        }
    },

    created() {
        document.title = this.setTitle(this.$t('messages.messages'));
        this.$store.dispatch('message/fetchList');

        this.read();
    }
};
</script>

<template>
    <Layout>
        <b-modal v-model="modal" :title="item.subject" centered hide-footer>
            {{ item.message }}
        </b-modal>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{ $t('messages.messages') }}</h4>
            </div>
            <div class="card-body p-0 pb-3 p-md-3">
                <div v-if="false" class="row">
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                                Show&nbsp;
                                <b-form-select
                                    v-model="perPage"
                                    :options="pageOptions"
                                    class="form-select form-select-sm"
                                    size="sm"
                                ></b-form-select
                                >&nbsp;entries
                            </label>
                        </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div
                            id="tickets-table_filter"
                            class="dataTables_filter text-md-end"
                        >
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <b-form-input
                                    v-model="filter"
                                    class="form-control form-control-sm ms-2"
                                    placeholder="Search..."
                                    type="search"
                                ></b-form-input>
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-3 mb-md-0">
                    <b-table
                        :current-page="currentPage"
                        :fields="fields"
                        :items="items"
                        :per-page="perPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        head-variant="light"
                        hover
                        responsive="sm"
                    >
                        <template #cell(actions)="data">
                            <div class="no-wrap">
                                <b-button v-b-tooltip.hover.top="$t('table.view')" class="me-2" size="sm" variant="primary" @click="open(data.item.m_id)">
                                    <i class="fas fa-eye"></i>
                                </b-button>
                                <b-button v-b-tooltip.hover.top="$t('table.delete')" size="sm" variant="danger" @click="destroy(data.item.m_id)">
                                    <i class="uil uil-trash-alt"></i>
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="d-flex w-100 align-items-center justify-content-center justify-content-md-end">
                            <div
                                class="dataTables_paginate paging_simple_numbers"
                            >
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                    ></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
